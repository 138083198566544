/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/no-danger */
import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Button, Tab, Tabs, IconRatingsStarFilled, IconRatingsStarHalf, IconRatingsStarUnfilled } from '@lmig/lmds-react';
import useEnvironmentalizeLink from "@lmig/dotcom-aspect-hooks/useEnvironmentalizeLink";
import useWindowWidth from '@lmig/dotcom-aspect-comparion-hooks/useWindowWidth';
import Image from '@lmig/dotcom-aspect-components/Utility/Image';
import Heading from '@lmig/dotcom-aspect-components/Utility/Heading';
import css from 'styled-jsx/css';
import Review from './Review';
import Rating from './Rating';

const { className: buttonClass, styles: buttonStyles } = css.resolve`
  *{
    margin: 0 auto;
  }
`;

const { className: tabsClass, styles: tabsStyles } = css.resolve`
  .lmig-Tabs{
    margin-top: -2rem;
  }
  :global(.reviewsTabs .lmig-Tabs-tabList-tab--isActive){
    flex: 1;
    flex-flow: row;
    justify-content: center;
  }
  :global(.reviewsTabs .lmig-Tabs-tabList-tab){
    flex: 1;
    flex-flow: row;
    justify-content: center;
  }
  :global(.reviewsTabs .lmig-Tabs-tabList-tab .lmig-Tabs-tabList-labelVisual){
    visibility: hidden;
  }
  :global(.reviewsTabs .lmig-Tabs-tabList-tab .lmig-Tabs-tabList-labelVisual-placeholder){
    visibility: unset;
    margin-top: 2rem;
  }
  :global(.reviewsTabs .lmig-Tabs-tabList-tab-icon){
    margin: 2rem 0.5rem 0 0;
  }
`;

const { className: tabSingleClass, styles: tabSingleStyles } = css.resolve`
  :global(.reviewsTabs .lmig-Tabs-tabList-tab-icon){
    display: flex;
  }
`;

const { className: iconClass, styles: iconStyles } = css.resolve`
  .lm-Icon{
    margin-bottom: 0.5rem;
  }
`;

const RenderReviews = ({ writeReview, rating, reviews, reviewLink, isComparion = false, reviewAria = "write a google review" }) => {
  const [reducedReviews, setReducedReviews] = useState([]);
  useEffect(() => {
    setReducedReviews(reviews.slice(0, 10));
  }, [reviews]);

  const increaseReviews = () => {
    setReducedReviews(reviews);
  };

  return (<>
    {reviews?.length ? (
      <section className={isComparion ? "comparionReviews" : "googleReviews"}>
        <div className="summary">
          {isComparion ? <Image imageDomain="COMPARION" preload>
            <img
              className="comparionLogo"
              alt="Comparion Insurance Logo"
              src="/images/comparion_logo_teal.svg"
              height="37px"
              width="190px"
            />
          </Image> : <Image imageDomain="IMAGE" deferLoad>
            <img
              className="googleLogo"
              src="/comparion/google-logo-fullname.svg"
              alt="Google Logo"
              width="142"
              height="50"
            />
          </Image>}
          <Rating rating={rating} count={reviews.length} type="agentOfficeReviews" />
        </div>
        <div className="content" tabIndex="0" onScroll={() => increaseReviews()}>{reducedReviews.map(review => (<Review key={`${review.authorName}-${review.publisherDate}`} review={review} schema />))}</div>
        {writeReview && <Button className={buttonClass} href={reviewLink} aria-label={reviewAria} id={isComparion ? "comparionReviewButton" : "googleReviewButton"}>Write a Review</Button>}
      </section>
    ) : (
      <section className={isComparion ? "comparionReviews" : "googleReviews"}>
        <div className="summary">
          {isComparion ? <Image imageDomain="COMPARION" preload>
            <img
              className="comparionLogoNoReviews"
              alt="Comparion Insurance Logo"
              src="/images/comparion_logo_teal.svg"
              height="50px"
              width="142px"
            />
          </Image> : <Image imageDomain="IMAGE" deferLoad>
            <img
              className="googleLogoNoReviews"
              src="/comparion/google-logo-fullname.svg"
              alt="Google Logo"
              width="142"
              height="50"
            />
          </Image>}
          <div className="noReviewsText">No reviews yet</div>
        </div>
        {writeReview && <Button className={buttonClass} href={reviewLink} aria-label={reviewAria} id={isComparion ? "comparionReviewButton" : "googleReviewButton"}>Write a Review</Button>}
      </section>
    )}
    {buttonStyles}
    <style jsx>{`
    section{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      &:not(:last-child){
        margin-right: 0.125rem;
        margin-bottom: 3rem;
      }
      @media screen and (min-width: 75rem) {
        width: 50%;
        margin-bottom: 0;
      }
    }
    .summary{
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      max-width: 24rem;
      min-height: 7.5rem;
      margin-top: none;
      margin-bottom: none;

      @media screen and (min-width: 25rem) {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }

      @media screen and (min-width: 48rem) {
        margin-top: none;
        margin-bottom: none;
      }
    }
    .rating{
      display: flex;
      align-items: center;
      flex-direction: column;

      @media screen and (min-width: 25rem) {
        flex-direction: row;
      }
    }
    .stars{
      margin-top: 0.25rem;
    }
    .ratingNumber{
      font-weight: 500;
      margin-right: 0.5rem;
    }
    .count{
      margin-left: 0.5rem;
      font-weight: 500;
    }
    .content{
      position: relative;
      border: 1px solid #e6e6e6;
      width: 100%;
      height: 18.75rem;
      overflow-y: scroll;
      margin-bottom: 3.125rem;

      @media screen and (min-width: 25rem) {
        width: 90%;
      }

      @media screen and (min-width: 48rem) {
        width: 100%;
      }

      @media screen and (min-width: 75rem) {
        height: 37.5rem;
      }
    }
    .noReviewsText{
      color: #707070;
      margin-bottom: 1rem;
    }
    .googleLogo{
      width: 99px;
      height: 25px;

      @media screen and (min-width: 25rem) {
        width: 142px;
        height: 50px;
      }
    }
    .comparionLogo{
      width: 120px;
      height: 40px;

      @media screen and (min-width: 25rem) {
        width: 215px;
        height: 50px;
      }
    }
    .comparionLogoNoReviews{
      width: 120px;
      height: 40px;
      margin-bottom: 1rem;

      @media screen and (min-width: 25rem) {
        width: 215px;
        height: 50px;
      }
      .googleLogoNoReviews{
        width: 99px;
        height: 25px;
        margin-bottom: 1rem;

        @media screen and (min-width: 25rem) {
          width: 142px;
          height: 50px;
        }
      }
    }
  `}</style>
  </>);
};

const Reviews = ({
  googleWriteReview,
  comparionWriteReview,
  googleRating = 0,
  comparionRating = 0,
  googleReviews = [],
  comparionReviews = [],
  isOffice = false,
  totalRating = 5
}) => {
  const { isMobile: isSmallerScreen } = useWindowWidth({ mobileWidth: 1200 });
  const { isMobile } = useWindowWidth({ mobileWidth: 425 });

  const googleReviewLink = useEnvironmentalizeLink({
    domain: "GOOGLE_SEARCH",
    link: `/local/writereview?placeid=${googleWriteReview}`,
  });
  const comparionReviewLink = comparionWriteReview;

  return (
    <>
      <Heading id="reviews" marginBottom="none" marginTop={isOffice ? '' : "none"} align="center" tag="h2" type={isMobile ? "h3-bold" : "h2-bold"} >
        Reviews
      </Heading>
      <div className="reviewsComponent">
        <div className='hiddenStars'>
          <IconRatingsStarFilled size="16" className={iconClass} id="lmds-filled" />
          <IconRatingsStarHalf size="16" className={iconClass} id="lmds-half-filled" />
          <IconRatingsStarUnfilled size="16" className={iconClass} id="lmds-not-filled" />
        </div>
        {((comparionReviews.length + googleReviews.length) > 0) &&
          <span itemScope itemProp="aggregateRating" itemType="http://schema.org/AggregateRating">
            <meta itemProp="ratingValue" content={totalRating} />
            <meta itemProp="reviewCount" content={comparionReviews.length + googleReviews.length} />
            <meta itemProp="bestRating" content="5" />
            <meta itemProp="worstRating" content="1" />
          </span>
        }
        {isSmallerScreen ? <Tabs className={`${tabsClass} reviewsTabs`} id="reviews">
          <Tab
            labelVisual="Google"
            tabIndex="0"
            id="googleReviews"
            className={tabSingleClass}
            icon={<Image imageDomain="IMAGE" type="circle" deferLoad>
              <img
                className="tabLogo"
                src="/comparion/google-logo.svg"
                alt="Google Logo"
                width="20"
                height="20"
              />
            </Image>}>
            <RenderReviews writeReview={googleWriteReview} rating={googleRating} reviews={googleReviews} reviewLink={googleReviewLink} />
          </Tab>
          <Tab
            labelVisual="Comparion"
            tabIndex="0"
            id="comparionReviews"
            className={tabSingleClass}
            icon={<Image imageDomain="IMAGE" type="circle" deferLoad>
              <img
                className="tabLogo"
                src="/comparion/comparion-logo.svg"
                alt="Comparion Logo"
                width="20"
                height="20"
              />
            </Image>}
          >
            <RenderReviews writeReview={comparionWriteReview} rating={comparionRating} reviews={comparionReviews} reviewLink={comparionReviewLink} isComparion reviewAria="write a comparion review" />
          </Tab>
        </Tabs> : <>
          <RenderReviews writeReview={googleWriteReview} rating={googleRating} reviews={googleReviews} reviewLink={googleReviewLink} />
          <RenderReviews writeReview={comparionWriteReview} rating={comparionRating} reviews={comparionReviews} reviewLink={comparionReviewLink} isComparion reviewAria="write a comparion review" />
        </>}
        <style jsx>{`
        .reviewsComponent{
          max-width: 80rem;
          display: flex;
          flex-direction: row;
          align-items: start;
          justify-content: space-around;
          margin-top: 2.5rem;
          margin-bottom: 2.5rem;
          gap: unset;
          @media screen and (min-width: 75rem) {
            gap: 4rem;
          }
        }
        .tabLogo{
          height: 20px;
          width:20px;
          @media screen and (min-width: 21rem) {
            height: 32px;
            width: 32px;
          }
        }
        .hiddenStars{
          display: none;
        }
      `}</style>
        {tabsStyles}
        {tabSingleStyles}
        {iconStyles}
      </div>
    </>
  );
};

Reviews.propTypes = {
  googleRating: PropTypes.number,
  comparionRating: PropTypes.number,
  googleWriteReview: PropTypes.string,
  comparionWriteReview: PropTypes.string,
  googleReviews: PropTypes.arrayOf(PropTypes.shape({
    rating: PropTypes.number,
    content: PropTypes.string,
    authorName: PropTypes.string,
    publisherDate: PropTypes.number,
    reviewLanguage: PropTypes.string,
    comments: PropTypes.arrayOf(PropTypes.shape({
      publisherDate: PropTypes.number,
      authorName: PropTypes.string,
      authorRole: PropTypes.string,
      authorEmail: PropTypes.string,
      content: PropTypes.string,
      visibility: PropTypes.string
    })),
  })),
  comparionReviews: PropTypes.arrayOf(PropTypes.shape({
    rating: PropTypes.number,
    content: PropTypes.string,
    authorName: PropTypes.string,
    publisherDate: PropTypes.number,
    reviewLanguage: PropTypes.string,
    comments: PropTypes.arrayOf(PropTypes.shape({
      publisherDate: PropTypes.number,
      authorName: PropTypes.string,
      authorRole: PropTypes.string,
      authorEmail: PropTypes.string,
      content: PropTypes.string,
      visibility: PropTypes.string
    })),
  })),
  isOffice: PropTypes.bool,
  totalRating: PropTypes.number,
};

export default Reviews;
